export const advisedPortfolio = [
  {
    id: 1,
    title: "wild.ai",
    img: "assets/wild.svg",
    url: "https://wild.ai",
  },
  {
    id: 2,
    title: "IDOVEN",
    img: "assets/idoven.svg",
    url: "https://idoven.ai",
  },
  {
    id: 3,
    title: "mySASY",
    img: "assets/mysasy.svg",
    url: "https://mysasy.com/",
  },
  {
    id: 4,
    title: "mymilk",
    img: "assets/mymilk.png",
    url: "https://mymilklab.com",
  },
  {
    id: 5,
    title: "apeteat",
    img: "assets/apeteat.svg",
    url: "https://apeteat.com",
  },
  {
    id: 6,
    title: "intake",
    img: "assets/intake.png",
    url: "https://www.instagram.com/intake_app/",
  },
  {
    id: 7,
    title: "BIOTTS",
    img: "assets/biotts.svg",
    url: "https://biotts.com",
  },
  {
    id: 8,
    title: "Rated Power",
    img: "assets/ratedpower.svg",
    url: "https://ratedpower.com",
  },
  {
    id: 9,
    title: "frontity",
    img: "assets/frontity.svg",
    url: "https://frontity.org",
  },
  {
    id: 10,
    title: "Doinn",
    img: "assets/doinn.png",
    url: "https://doinn.co",
  },
  {
    id: 11,
    title: "Routive",
    img: "assets/routive.png",
    url: "https://routive.com",
  },
];

export const investedPortfolio = [];

export const boardPortfolio = [];
