import "./topbar.scss";
import { LinkedIn, Mail } from "@mui/icons-material";

export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            caslab.ventures
          </a>
          <div className="itemContainer">
            <LinkedIn className="icon" />
            <span><a href="https://uk.linkedin.com/in/victorcaslab" target="_blank" rel="noreferrer">victorcaslab</a></span>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <span><a href="mailto:victor@caslab.ventures">victor@caslab.ventures</a></span>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
