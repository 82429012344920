import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import {
    advisedPortfolio,
    investedPortfolio,
    boardPortfolio,
} from "../../data";

export default function Portfolio() {

    const [selected, setSelected] = useState("advised");
    const [data, setData] = useState([]);
    const list = [
        {
            id: "advised",
            title: "Advised",
        },
        {
            id: "invested",
            title: "Invested",
        },
        {
            id: "board",
            title: "Board member",
        },
    ];

    useEffect(() => {
        switch (selected) {
            case "advised":
                setData(advisedPortfolio);
                break;
            case "invested":
                setData(investedPortfolio);
                break;
            case "board":
                setData(boardPortfolio);
                break;
            default:
                setData(advisedPortfolio);
        }
    }, [selected]);

    return (
        <div className="portfolio" id="portfolio">
            <h1>Startups</h1>
            <ul>
                {list.map((item) => (
                    <PortfolioList
                        title={item.title}
                        active={selected === item.id}
                        setSelected={setSelected}
                        id={item.id}
                    />
                ))}
            </ul>
            <div className="container">
                {data.map((d) => (
                    <div className="item">
                        <a href={d.url} target="_blank" rel="noreferrer">
                            <img
                                src={d.img}
                                alt={d.title}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}