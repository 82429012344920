import { LinkedIn, Twitter, Facebook, Instagram, Mail } from "@mui/icons-material";
import "./contact.scss";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <h1>Contact</h1>
      <div className="bottomContainer">
        <div className="top">
          <div className="socialContainer">
            <a href="https://uk.linkedin.com/in/victorcaslab" target="_blank" rel="noreferrer">
              <LinkedIn className="icon" />
            </a>
            <a href="https://twitter.com/victor_caslab" target="_blank" rel="noreferrer">
              <Twitter className="icon" />
            </a>
            <a href="https://facebook.com/victorcaslab" target="_blank" rel="noreferrer">
              <Facebook className="icon" />
            </a>
            <a href="https://instagram.com/cas.lab" target="_blank" rel="noreferrer">
              <Instagram className="icon" />
            </a>
            <a href="mailto:victor@caslab.ventures">
              <Mail className="icon" />
            </a>
          </div>
        </div>
        <h2>Victor Castano Labajo</h2>
        <div className="bottom">
          <img src="assets/shake.svg" alt="" />
        </div>
      </div>
    </div>
  );
}
