import "./testimonials.scss";

export default function Testimonials() {
  const data = [
    {
      id: 1,
      name: "Juan Romero",
      title: "CTO of RatedPower",
      img:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/xtshje5rnbtu1ao4vrch",
      icon: "assets/writing.png",
      desc:
        "The sessions with Victor have allowed us to take a leap in quality and organization. Having a well structured agile methodology has allowed us to grow the team and work remotely more effectively.",
    },
    {
      id: 2,
      name: "Gabriel Rodriguez",
      title: "CEO of Routive",
      img:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/nwvrvfmh7sxb1agmshss",
      icon: "assets/writing.png",
      desc:
        "Thanks to the work we did together we are now a lot more efficient in allocating our dev resources and everybody has a clear vision of what we are building, saving us many hours of wasted work. As a CEO, this roadmap has also been instrumental in communicating our product vision to investors and other stakeholders. Victor dedicated us as much time as we needed and was always available and happy to help. He was fun and working with him was a real pleasure.",
      featured: true,
    },
    {
      id: 3,
      name: "Luis Herranz",
      title: "CTO of Frontity",
      img:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_170,w_170,f_auto,g_faces,z_0.7,b_white,q_auto:eco,dpr_1/ggn06hyqsvwxizzmajo5",
      icon: "assets/writing.png",
      desc:
        "Thanks to the help that Victor provided us during the Google Experts Summit we have been able, not only to learn how to build a good product roadmap, but also how to adapt it to the specific needs of our business, how to create processes to keep it up to date, and how to set priorities according to the needs of our customers. Now our roadmap has become one of the fundamental pieces of what we do.",
    },
  ];
  return (
    <div className="testimonials" id="testimonials">
      <h1>Founders say..</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img src="assets/right-arrow.png" className="left" alt="" />
              <img
                className="user"
                src={d.img}
                alt=""
              />
              <img className="right" src={d.icon} alt="" />
            </div>
            <div className="center">
              {d.desc}
            </div>
            <div className="bottom">
              <h3>{d.name}</h3>
              <h4>{d.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
